import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Grid from "@material-ui/core/Grid"
import theme from "../../theme"

const useStyles = makeStyles({
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  footerText: {
    fontSize: "90%",
    letterSpacing: ".1rem",
    fontWeight: "bold",
  },
})

export default function Footer() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <AppBar position="static" color="inherit" className={classes.appBar}>
        <Toolbar>
          <Grid container direction="row" justify="center" alignItems="stretch">
            <span>
              made by yours truly{" "}
              <span
                role="img"
                aria-label="copyright"
                className={classes.footerText}
              >
                ©
              </span>{" "}
              <span className={classes.footerText}>
                <span id="copyrightYear">2021</span> TNOOR
              </span>
            </span>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}
