import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGrin } from "@fortawesome/free-regular-svg-icons"
import { faMobileAlt, faUserAstronaut } from "@fortawesome/free-solid-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import theme from "../../theme"
import Link from "./link"
import ContactMe from "./contactMe"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.grey[50],
  },
  drawer: {
    flexShrink: 0,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  icons: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "20px",
  },
  list: {
    width: 250,
  },
  listDrawer: {
    paddingRight: "75px",
  },
})

export default function NavBar() {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const [isContactMeOpen, setContactMeOpen] = React.useState(false)

  function openContactMe() {
    setContactMeOpen(true)
  }

  const closeContactMe = () => {
    setContactMeOpen(false)
  }

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const linkNavBar = (path, icon, text) => (
    <Link to={path} className="link-no-style">
      {gridNavBar(icon, text)}
    </Link>
  )

  const externalLinkNavBar = (externalLink, icon, text, newTab) => (
    <a
      href={externalLink}
      className="link-no-style"
      rel={newTab === true ? "noreferrer" : null}
      target={newTab === true ? "_blank" : null}
    >
      {gridNavBar(icon, text)}
    </a>
  )

  const listNavDrawerExternal = (icon, text, link) => (
    <ListItem
      button
      key={text}
      href={link}
      component="a"
      rel="noreferrer"
      target="_blank"
      className={classes.listDrawer}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{linkText(text)}</ListItemText>
    </ListItem>
  )

  const listNavDrawerInternal = (icon, text, path) => (
    <Link to={path} className="link-no-style">
      <ListItem button key={text} className={classes.listDrawer}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{linkText(text)}</ListItemText>
      </ListItem>
    </Link>
  )

  const listNavDrawerContactMe = (icon, text) => (
    <ListItem
      button
      key={text}
      className={classes.listDrawer}
      onClick={openContactMe}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{linkText(text)}</ListItemText>
    </ListItem>
  )

  const gridNavBar = (icon, text) => (
    <Button className={classes.button}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        {icon}
        {linkText(text)}
      </Grid>
    </Button>
  )

  const gridNavBarContactMe = (icon, text) => (
    <Button className={classes.button} onClick={openContactMe}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        {icon}
        {linkText(text)}
      </Grid>
    </Button>
  )

  const linkText = text => (
    <Typography variant="h6" className="bold90Spaced text-uppercase primeColor">
      {text}
    </Typography>
  )

  const linkIcon = (icon, text) => (
    <FontAwesomeIcon
      icon={icon}
      title={text}
      color={theme.palette.primary.main}
      className={classes.icons}
    />
  )

  const sideDrawer = (
    <SwipeableDrawer
      anchor="right"
      open={state.right}
      onClose={toggleDrawer("right", false)}
      onOpen={toggleDrawer("right", true)}
    >
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer("right", false)}
        onKeyDown={toggleDrawer("right", false)}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <List>
            {listNavDrawerInternal(
              linkIcon(faUserAstronaut, "Home"),
              "home",
              "/"
            )}
          </List>
          <Divider />
          <List>
            {listNavDrawerExternal(
              linkIcon(faMobileAlt, "My Work"),
              "My Work",
              "https://noorby.design/?utm-source=devsite"
            )}
          </List>
          <Divider />
          <List>
            {listNavDrawerExternal(
              linkIcon(faLinkedin, "LinkedIn"),
              "LinkedIn",
              "https://www.linkedin.com/in/tnoor/"
            )}
            <Divider />
          </List>
          <List>
            {listNavDrawerContactMe(linkIcon(faGrin, "Say Hello"), "Say Hello")}
          </List>
        </Grid>
      </div>
    </SwipeableDrawer>
  )

  const linksOnNavBar = (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      {externalLinkNavBar(
        "https://noorby.design/?utm-source=devsite",
        linkIcon(faMobileAlt, "My Work"),
        "my work",
        false
      )}
      {externalLinkNavBar(
        "https://www.linkedin.com/in/tnoor/",
        linkIcon(faLinkedin, "LinkedIn"),
        "LinkedIn",
        true
      )}
      {gridNavBarContactMe(linkIcon(faGrin, "Say Hello"), "Say Hello")}
    </Grid>
  )

  const navBarMenuButton = (
    <IconButton
      className={classes.menuButton}
      edge="end"
      color="inherit"
      aria-label="menu"
      onClick={toggleDrawer("right", true)}
    >
      <MenuIcon />
    </IconButton>
  )

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              {linkNavBar("/", linkIcon(faUserAstronaut, "Astronaut"), "TNoor")}
            </Grid>
            <Hidden smDown>
              <Grid item>{linksOnNavBar}</Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item>{navBarMenuButton}</Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      {sideDrawer}
      <ContactMe open={isContactMeOpen} onClose={closeContactMe} />
    </div>
  )
}
