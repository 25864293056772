import React from "react"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Navbar from "./widgets/navBar"
import Footer from "./widgets/footer"
import theme from "../theme"

const noPadding = {
  paddingLeft: 0,
  paddingRight: 0,
}

const useStyles = makeStyles({
  toolbar: theme.mixins.toolbar,
})

const Layout = ({ children, pageInfo }) => {
  const classes = useStyles()
  return (
    <>
      <Navbar pageInfo={pageInfo} />
      <div className={classes.toolbar} />
      <Container maxWidth={false} style={noPadding}>
        {children}
      </Container>
      <Footer />
    </>
  )
}

export default Layout
