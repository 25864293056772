import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import EmailIcon from "@material-ui/icons/Email"
import PhoneIcon from "@material-ui/icons/PhoneIphone"
import theme from "../../theme"

const useStyles = makeStyles({
  avatar: {
    backgroundColor: theme.palette.text,
    color: theme.palette.primary.main,
  },
})

const textColor = {
  color: theme.palette.primary.main,
}

function ContactMe(props) {
  const classes = useStyles()
  const { onClose, open } = props

  function handleClose() {
    onClose()
  }

  function handleListItemClick(value) {
    onClose()
    if (value === "email") {
      window.location.href = "mailto:hello@tnoor.dev"
    } else {
      window.location.href = "sms:+17278886113"
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="contactMe"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle
        id="contactMe"
        className="text-uppercase boldSpaced"
        disableTypography={true}
        style={textColor}
      >
        Let's Connect
      </DialogTitle>
      <List>
        <ListItem
          button
          onClick={() => handleListItemClick("email")}
          key="email"
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <EmailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="email"
            className="text-uppercase bold90Spaced"
            disableTypography={true}
          />
        </ListItem>

        <ListItem
          button
          onClick={() => handleListItemClick("phone")}
          key="phone"
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <PhoneIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="phone"
            className="text-uppercase bold90Spaced"
            disableTypography={true}
          />
        </ListItem>
      </List>
    </Dialog>
  )
}

ContactMe.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default ContactMe
